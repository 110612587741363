import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import dfxHello from "../../../images/dfx_hello_fresh.png";
import dfxCompiled from "../../../images/hello_compiled.png";
import { BlogImage } from "../../../components/BlogComponents";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <details>
  <summary>Comment on current events</summary>
  Before I get started, I'm writing this between January 6, when Trump supporters
  stormed the US Capitol, and January 20th, when Joe Biden is set to be inaugurated
  as president. As unsettling as it is to see unfold, it is a reminder that the United
  States is still suffering under institutions that coddle white supremacy and racial
  resentment, stoked by income inequality and unscrupulous politicians. I'd like
  to simply state that Black and Indigenous lives matter, LGBTQIA+ lives matter,
  and that systems of power that benefit some over the good of the many are wrong,
  even when I am part of the group benefiting.
    </details>
    <br />
Curious about how frontend code works on the Internet Computer? Here's what I've learned after two weeks on the job.
    <h2 {...{
      "id": "what-you-get-in-the-starter"
    }}><a parentName="h2" {...{
        "href": "#what-you-get-in-the-starter"
      }}>{`What you get in the starter`}</a></h2>
    <p>{`If you're following the `}<a parentName="p" {...{
        "href": "https://sdk.dfinity.org/docs/quickstart/quickstart-intro.html"
      }}>{`Quick Start guide`}</a>{`, you'll get some useful instructions on how to install the `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` sdk, leading you to create a templated project using `}<inlineCode parentName="p">{`dfx new hello`}</inlineCode>{`.`}</p>
    <BlogImage src={dfxHello} mdxType="BlogImage">
  <br />
  How your project looks immediately after init
    </BlogImage>
    <p>{`Things will largely look familiar to you if you've been working with frontend code for a while. You've got `}<inlineCode parentName="p">{`src`}</inlineCode>{` and `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` folders, as well as `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` and `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{` config files.`}</p>
    <p>{`There is also a `}<inlineCode parentName="p">{`dfx.json`}</inlineCode>{` config file, which is worth opening up and inspecting.`}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// dfx.json`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"canisters"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"hello"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"main"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/hello/main.mo"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"motoko"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"hello_assets"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"dependencies"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"hello"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"frontend"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"entrypoint"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/hello_assets/public/index.js"`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"source"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"src/hello_assets/assets"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"dist/hello_assets/"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"assets"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"defaults"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"build"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"packtool"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"dfx"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"0.6.17"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"networks"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"ic"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"providers"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"https://gw.dfinity.network"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"persistent"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"local"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"bind"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"127.0.0.1:8000"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"ephemeral"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"version"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`Inside, you'll see that the template has provided you with two "canisters": `}<inlineCode parentName="p">{`hello`}</inlineCode>{` and `}<inlineCode parentName="p">{`hello_assets`}</inlineCode>{`. Their names are generated to match the name of your new project you provided during `}<inlineCode parentName="p">{`dfx new`}</inlineCode>{`.`}</p>
    <p>{`Those canisters point to two directories under your `}<inlineCode parentName="p">{`src`}</inlineCode>{` folder. One has some motoko code, and the other is JavaScript. If you're a person, the motoko code is probably new to you, but even the JavaScript has something that looks a little odd to you.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// src/hello_assets/public/index.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`hello`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"ic:canisters/hello"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

hello`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`greet`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token dom variable"
        }}>{`window`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`prompt`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Enter your name:"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`then`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`greeting`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`window`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`alert`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`greeting`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`hello`}</inlineCode>{` object imported from `}<inlineCode parentName="p">{`ic:canisters/hello`}</inlineCode>{` is clearly nonstandard, but we'll get to what it's doing in a second.`}</p>
    <h2 {...{
      "id": "build-your-starter"
    }}><a parentName="h2" {...{
        "href": "#build-your-starter"
      }}>{`Build your starter`}</a></h2>
    <p>{`To get your starter running, you'll need to run a few commands. First, run `}<inlineCode parentName="p">{`dfx start`}</inlineCode>{` and then open a new terminal tab or window.`}</p>
    <p>{`In the new window, you need to create your on a local version of the internet computer, then compile your code.`}</p>
    <pre {...{
      "className": "language-shell"
    }}><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`dfx canister create hello
dfx canister create hello_assets
dfx build
`}</code></pre>
    <p>{`Now, you should see that you have a `}<inlineCode parentName="p">{`.dfx`}</inlineCode>{` directory, with a bunch of new content inside. The interesting bits will be the files inside of `}<inlineCode parentName="p">{`.dfx/local/canisters`}</inlineCode>{`, inside the `}<inlineCode parentName="p">{`hello`}</inlineCode>{` and `}<inlineCode parentName="p">{`hello_assets`}</inlineCode>{` directories.`}</p>
    <BlogImage src={dfxCompiled} mdxType="BlogImage">
  <br />
  new dfx folder
    </BlogImage>
    <p>{`You'll see that the canisters compile into `}<inlineCode parentName="p">{`wasm`}</inlineCode>{` code, which is what runs on the canisters, as well as a candid type delcaration file `}<inlineCode parentName="p">{`hello.did`}</inlineCode>{`, and JavaScript files that set up an interface for your API.`}</p>
    <p>{`This code is what enables the `}<inlineCode parentName="p">{`ic:canisters/hello`}</inlineCode>{` import that we saw earlier in our source code.`}</p>
    <p>{`If you look into the `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{`, you'll see that there is a pre-filled configuration that uses Webpack's alias feature to link your `}<inlineCode parentName="p">{`ic:canisters/hello`}</inlineCode>{` to `}<inlineCode parentName="p">{`.dfx/local/canisters/hello/hello.js`}</inlineCode>{`, along with its type declaration file `}<inlineCode parentName="p">{`hello.did.js`}</inlineCode>{`.`}</p>
    <p>{`If you're looking closely at `}<inlineCode parentName="p">{`hello.js`}</inlineCode>{`, however, you'll notice something that's not immediately clear. It attaches its interface to a global object, `}<inlineCode parentName="p">{`ic.agent`}</inlineCode>{`. You can run a search on your codebase, but you'll see that there's nowhere that sets up this global `}<inlineCode parentName="p">{`ic`}</inlineCode>{` object that you can access.`}</p>
    <p>{`Still, you can navigate to your browser, pull up `}<inlineCode parentName="p">{`localhost:8000`}</inlineCode>{` and pass your canister id as a query parameter, and you'll get an html payload that loads and executes your code. The reason that the code works is that the asset canister has a few assumptions and some magic it's doing for you.`}</p>
    <ul>
      <li parentName="ul">{`An asset canister bootstraps your identity with html that initializes a global `}<inlineCode parentName="li">{`ic`}</inlineCode>{` object and an anonymous identity.`}</li>
      <li parentName="ul">{`That anonymous identity allows you to request static assets, including your JS bundle.`}</li>
    </ul>
    <p>{`From there, you have a workflow that will allow you to run a compile job and deploy a JavaScript application to the Internet Computer. You can work with any JavaScript technology that compiles with WebPack, and build your application.`}</p>
    <h2 {...{
      "id": "wait-i-have-to-manually-build-and-deploy-every-time-that-sounds-slow-and-bad"
    }}><a parentName="h2" {...{
        "href": "#wait-i-have-to-manually-build-and-deploy-every-time-that-sounds-slow-and-bad"
      }}>{`Wait, I have to manually build and deploy every time? That sounds slow and bad.`}</a></h2>
    <p>{`Yeah, I agree with you on that one, and I think we can make some big improvements. In my next post, I'll get into how you can use the `}<inlineCode parentName="p">{`@dfinity/agent`}</inlineCode>{` package and a polyfill to create your own global `}<inlineCode parentName="p">{`ic`}</inlineCode>{` object to start working with `}<inlineCode parentName="p">{`webpack-dev-server`}</inlineCode>{` and enable a more modern workflow. We're actively working on making this process friendlier internally, but if you're eager to get started, you can check out our unofficial Create React App `}<a parentName="p" {...{
        "href": "https://github.com/taylorham/cra-template-dfx"
      }}>{`DFX Template`}</a>{` work in progress. Credit to `}<a parentName="p" {...{
        "href": "https://github.com/MioQuispe"
      }}>{`Mio Quispe`}</a>{` for their work on the proof of concept and starting point for our project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      